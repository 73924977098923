/* .side_bar_section > .btn img {
    filter: invert(1) !important;
}

.search_inner.open {
    height: 140px !important;
}

.btn.bonus {
    background-color: rgb(104 162 0) !important;
}

.footer-wrapper {
    background-color: rgb(104 162 0/40%) !important;
}

.footer-imgs img {
    filter: grayscale(1) drop-shadow(2px 4px 6px black) !important;
}

.jackpot_wrapper {
    background: rgb(40 61 2) !important;
    border-image: linear-gradient(0.25turn, #161816, #adb4b3, #161816) 2 0 2 0 !important;
} */
.bonus_wrapper{
  justify-content: start;
  .bonus_container{
    margin-left: 70px;
    img{
      height: 75% !important;
    }
  }
}
.search_icon{
  @include recolor(#f0b7b1)
}
.footer-section span{
  color: rgba(255, 255, 255, 0.76);
}
.btn.bonus {
  right: 7vw;
  bottom: 80px;
  top: auto;
  height: 70%;
}

.header-logo {
  width: 20vw;
  position: relative;
  left: auto;
  height: 90%;
}

.bonus_slider {
 margin-top: 0;
  height: 80vh;
}

.bonus_wrapper {
  margin-top: 50px;
  width: 100vw;
  height: 80vh;
  min-height: 250px;
  max-height: initial;
}
.carousel-initialized{
  padding: 0 !important;
}
@media screen and (max-width: 600px) {
  .bonus_wrapper{
    justify-content: center;
    .bonus_container{
      margin-left: 0px;
    }
  }
  .btn.bonus {
    margin-top:40px;
    height: initial;
  }

}
